import React from "react";
import type { Condition } from "~/types";

// ... existing code ...
type Props = {
  text: string;
  labelColor: string /* primaryが職種、secondaryが業態、tertiaryがエリア */;
  handleSubCondition?: (
    type: keyof Condition,
    value: string | string[]
  ) => void; // オプショナルに変更
  type: keyof Condition;
  value: string | string[];
};

const LabelDeletable: React.FC<Props> = (props) => {
  const [deleted, setDeleted] = React.useState(false);
  const stateToggle = () => {
    setDeleted(!deleted);
    if (props.handleSubCondition) {
      props.handleSubCondition(props.type, props.value);
    }
  };
  return (
    <div
      onClick={stateToggle}
      onKeyDown={stateToggle}
      role="button"
      tabIndex={0}
      className={`group relative inline-block px-3 py-2.5 rounded-full text-xs leading-[1.35] font-bold md:py-1 md:text-sm md:leading-[1.8] ${
        deleted ? "deleted" : ""
      } [&.deleted]:hidden ${
        props.labelColor
      } [&.primary]:bg-primary-light [&.primary]:text-primary hover:[&.primary]:bg-primary hover:[&.primary]:text-primary-light [&.secondary]:bg-secondary-light [&.secondary]:text-secondary hover:[&.secondary]:bg-secondary hover:[&.secondary]:text-secondary-light [&.tertiary]:bg-tertiary-light [&.tertiary]:text-tertiary hover:[&.tertiary]:bg-tertiary hover:[&.tertiary]:text-tertiary-light`}
    >
      {props.text}
      <div className="absolute -top-1.5 -right-2 w-5 h-5 opacity-0 group-hover:opacity-100">
        <span className="flex items-center justify-center w-full h-full rounded-full bg-muted">
          <svg className="block w-2 h-2 fill-white">
            <use xlinkHref="/symbol-defs.svg#icon-delete"></use>
          </svg>
        </span>
      </div>
    </div>
  );
};

export default LabelDeletable;
